<template>
  <div class="container">
    <a-empty v-if="blogs.length == 0"></a-empty>
    <a-list v-if="blogs.length > 0">
      <a-list-item v-for="item in blogs" :key="item.id" class="eachItem">
        <img :src="item.cover" alt="" height="130" width="200" @click="goDetail(item)" 
        v-if="item.cover !== undefined && item.cover !== null">
        <div class="textSide">
          <h2 class="title" @click="goDetail(item)">{{item.title}}</h2>
          <span style="float: left;"><a-tag color="#7B8184">
            标签:</a-tag><a-tag v-for="(item,index) in item.tags" :key="index">{{item.name}}</a-tag>
          </span>
          <br />
          <a-descriptions class="desc">
            <a-descriptions-item @click="goDetail(item)">
              {{item.description}}
            </a-descriptions-item>
          </a-descriptions>
          <div class="count">
            <span class="countItem"><user-outlined />{{item.author}}</span>
            <span class="countItem"><heart-filled />{{item.likes}}</span>
            <span class="countItem"><message-outlined />{{item.cmtNum}}</span>
            <span class="countItem"><calendar-outlined />{{item.pubTime}}</span>
            <span class="countItem"><eye-outlined />{{item.viewNum}}</span>
          </div>
        </div>
        <div class="operation">
          <a-dropdown class="optItem" >
            <small-dash-outlined style="font-size: 26px; margin-right: 20px; "  />
            <template #overlay >
              <a-menu style="margin-top: -18px;">
                <a-menu-item>
                  <edit-outlined style="margin-right: 8px;" />
                  <span @click="goEdit(item)">修改文章</span>
                </a-menu-item>
                <a-menu-item>
                  <alert-outlined style="margin-right: 8px;" />
                  <span @click="delBlog(item)">删除文章</span>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </div>
      </a-list-item>
    </a-list>
    <a-pagination
    style="margin-top: 20px;"
    v-if="blogs.length > 0"
    :total="pagination.total"
    :show-total="total => `总共 ${total} 篇博客`"
    :page-size="pagination.size"
    :default-current="1"
    @change="pageChange"
    />
  </div>
</template>
<script>
  import * as BLOGS from '../../api/blog.js';
  import { UserOutlined, HeartFilled, MessageOutlined, CalendarOutlined, EyeOutlined, StarOutlined, EditOutlined, 
    AlertOutlined, SmallDashOutlined } from '@ant-design/icons-vue';
  export default {
    name: 'MyBlogList',
    components: {
      UserOutlined,
      HeartFilled,
      MessageOutlined,
      CalendarOutlined,
      EyeOutlined,
      StarOutlined,
      EditOutlined,
      AlertOutlined,
      SmallDashOutlined
    },
    data() {
      return {
        pagination: {
          current: 1,
          total: 0,
          size: 10,
        },
        blogs: []
      }
    },
    mounted() {
      this.loadData(this.$route.params.pid, 1,10)
    },
    watch: {
      '$route.params.pid': {
        handler: function(pid) {
          // console.log(id)
          if(pid!==undefined){
            this.loadData(pid, 1,10)
          }
        },
        deep: true,
        immediate: true
      }
    },
    methods: {
      loadData(uid, page, size) {
        let params = new FormData()
        params.append('uid', uid)
        params.append('page', page)
        params.append('size', size)
        BLOGS.searchBlogByUId(params).then(res=>{
          // console.log('my blog ', res)
          if(res.data.code == 200 && res.data.data) {
            this.blogs = res.data.data.records
            this.pagination.current = res.data.data.current
            this.pagination.total = res.data.data.total
            this.pagination.size = res.data.data.size
          }
        })
      },
      goDetail(item) {
        this.$router.push({
          path: '/blog',
          query: {
            id: item.id
          }
        })
      },
      pageChange(page, pageSize) {
        // console.log('page', page, 'size', size)
        this.loadData(this.$route.params.pid, page, pageSize)
      },
      goEdit(item) {
        // console.log('item', item)
        this.$router.push({
          path: '/editBlog',
          query: {
            bid: item.id
          }
        })
      },
      delBlog(item) {
        // console.log('del item', item)
        let _that = this;
        this.$confirm({
          title: '删除博客',
          content: '一经删除将无法恢复，确定删除？',
          okText: '确定',
          okType: 'danger',
          cancelText: '取消',
          onOk() {
            // console.log('OK');
            let params = new FormData()
            params.append('bid', item.id)
            BLOGS.delBlogByBid(params).then(res=>{
              // console.log('del', res)
              if(res.data.data) {
                _that.$message.success('删除成功');
                _that.loadData(_that.$route.params.pid, 1,10)
              }else {
                _that.$message.error('删除失败');
              }
            })
          },
          onCancel() {
            // console.log('Cancel');
          },
        });
      }
    },
  }
</script>
<style scoped>
  .container {
    width: 640px;
    height: 100%;
  }
  .eachItem {
    width: 650px;
    height: 150px;
    margin-top: 20px;
    background-color: #fff;
    border-radius: 10px;
  }
  
  .textSide {
    width: 450px;
    margin-left: 16px;
  }
  .title {
    text-align: left;
    font-size: 18px;
    line-height: 27px;
    height: 27px;
    font-family: -apple-system,SF UI Display,Arial,PingFang SC,Hiragino Sans GB,Microsoft YaHei,WenQuanYi Micro Hei,sans-serif;
  }
  .desc {
    margin-top: 6px;
    text-align: left;
    text-overflow: ellipsis;
    font-size: 13px;
    color: #999;
    line-height: 24px;
    max-height: 72px;
  }
  .count {
    text-align: left;
  }
  .countItem:not(:first-child) {
    margin-left: 12px;
  }
  .operation {
    width: 30px;
    height: 150px;
    margin-top: 1px;
  }
</style>